import { Box, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle } from "reactstrap";

const StatisticWidget = ({ url, title, value, icon, color }) => {
  const temp = color || "primary";

  return (
    <Card className={"card-stats mb-0 mb-md-4 mb-xl-0 bg-" + temp}>
      <CardBody className="p-2">
        <Link to={url}>
          <Stack direction="row" spacing={1}>
            <Box className={"icon icon-shape bg-white rounded-circle shadow text-" + temp}>
              <i className={icon} />
            </Box>

            <Box className="col-auto">
              <CardTitle tag="h5" className="text-uppercase text-white mb-0">
                {title}
              </CardTitle>
              <span className="h2 font-weight-bold mb-0 text-white">{value}</span>
            </Box>
          </Stack>
        </Link>
      </CardBody>
    </Card>
  );
};

export default StatisticWidget;
