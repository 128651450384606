import { useMemo, useState, useRef } from "react";
import PackageIdentifier from "./PackageIdentifier";
import UtilityService from "service/utility";
import { PACKAGE_STATUS_LIST } from "variables/constant";

const PackageTableColumn = () => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "الرقم التسلسلي",
        filterVariant: "text",
        size: 200,
        Cell: ({ cell }) => <PackageIdentifier package={cell.row.original} />,
      },
      {
        accessorKey: "refrencesNumber",
        header: "الرقم المرجعي",
      },
      {
        header: "الحالة",
        accessorKey: "status",
        filterVariant: "multi-select",
        Cell: ({ cell }) => UtilityService.renderStatusMessage("PACKAGE", cell.getValue()),
        size: 170,
        filterSelectOptions: PACKAGE_STATUS_LIST.map((s) => {
          return { label: UtilityService.renderStatusMessage("PACKAGE", s, false, true), value: s };
        }),
      },
      {
        accessorFn: (originalRow) => originalRow.organization.name,
        id: "organizationName",
        header: "المرسل",
        filterVariant: "text",
        muiTableHeadCellProps: {
          align: "left",
        },
      },
      {
        accessorFn: (originalRow) =>
          `${originalRow.address.firstName} ${originalRow.address.lastName ? originalRow.address.lastName : ""}`,
        id: "customerName",
        header: "المستقبل",
        filterVariant: "text",
      },
      {
        accessorFn: (originalRow) => {
          const { mobile, mobile2 } = originalRow.address;
          return (
            <>
              {mobile}
              {mobile2 && (
                <>
                  <br />
                  {mobile2}
                </>
              )}
            </>
          );
        },
        id: "customerMobile",
        header: "رقم المستقبل",
        filterVariant: "text",
      },
      {
        accessorFn: (originalRow) => UtilityService.formatAddress(originalRow.address, true),
        id: "customerAddress",
        header: "العنوان",
        filterVariant: "text",
        muiTableHeadCellProps: {
          align: "left",
        },
      },
      {
        accessorFn: (originalRow) => new Date(originalRow.created), //convert to date for sorting and filtering
        header: "تاريخ الادخال",
        filterVariant: "date-range",
        Cell: ({ cell }) => cell.getValue().toLocaleDateString(), // convert back to string for display
      },
      {
        accessorFn: (originalRow) => (originalRow.deliveryDate ? new Date(originalRow.deliveryDate) : ""), //convert to date for sorting and filtering
        header: "تاريخ التوصيل",
        filterVariant: "date-range",
        Cell: ({ cell }) => (cell.getValue() ? cell.getValue().toLocaleDateString() : ""), // convert back to string for display
      },
      {
        accessorKey: "cost",
        header: "التكلفة",
        size: 120,
        Cell: ({ cell }) =>
          cell.getValue()?.toLocaleString("en-US", {
            style: "currency",
            currency: "ILS",
          }),
      },
      {
        accessorKey: "totalCollection",
        header: "التحصيل",
        size: 120,
        Cell: ({ cell }) =>
          cell.getValue()?.toLocaleString("en-US", {
            style: "currency",
            currency: "ILS",
          }),
      },
      {
        accessor: "requiredForMerchant",
        header: "مطلوب لتاجر",
        size: 150,
        Cell: ({ cell }) => {
          const requiredForMerchant = cell.row.original.totalCollection - cell.row.original.cost;
          return requiredForMerchant.toLocaleString("en-US", {
            style: "currency",
            currency: "ILS",
          });
        },
      },
      {
        accessorKey: "trackingNumber",
        header: "رابط مشاركة",
        Cell: ({ cell }) => (
          <a target="_blank" href={process.env.REACT_APP_PACKAGE_TRACKING_URL + "?id=" + cell.getValue()}>
            {cell.getValue()}
          </a>
        ),
      },
      {
        accessorKey: "customerNote",
        header: "ملاحظات",
      },
    ],
    []
  );

  return columns;
};

export default PackageTableColumn;
