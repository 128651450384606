import { useMemo, useState, useRef } from "react";

import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

//Date Picker Imports - these should just be in your Context Provider
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

//Material UI Imports
import { ListItemIcon, MenuItem } from "@mui/material";

//Icons Imports
import { Person, Print, Check, CalendarToday, Link } from "@mui/icons-material";

import { MRT_Localization_AR } from "material-react-table/locales/ar/index.js";

import { ThemeProvider, createTheme, useTheme } from "@mui/material";
import { arSA } from "@mui/material/locale";
import UtilityService from "service/utility";
import { PACKAGE_STATUS_LIST } from "variables/constant";
import PackageTableTopBar from "./PackageTableTopBar";
import UpdateDeliveryCost from "./UpdateDeliveryCost";
import UpdateCollectionCost from "./UpdateCollectionCost";
import PackageService from "service/package";
import PackageIdentifier from "./PackageIdentifier";
import ChangeDeliveryDateModel from "./ChangeDeliveryDateModel";
import LinkToExternalSystemModal from "./LinkToExternalSystemModal";
import PackageTableColumn from "./PackageTableColumn";
import { calc } from "antd/es/theme/internal";

const Table = (props) => {
  const { data } = props;
  const editingRow = useRef(null);
  const [isChangeModalOpen, setChangeModalOpen] = useState(false);
  const [isSetFilter, setFilter] = useState(false);
  const status = new URLSearchParams(window.location.search).get("status");

  const handlePrintBill = () => {
    const id = [editingRow.current.row.original.id];
    PackageService.printBill(id).then((res) => {
      if (res.data.success) {
        const newTab = window.open(process.env.REACT_APP_STATIC_REPORT_URL + res.data.fileName, "_blank");
        if (newTab) {
          newTab.focus();
        }
      }
    });
  };

  const handleDeliveryCostChange = (newValue) => {
    const id = [editingRow.current.row.original.id];

    PackageService.updatePackageCost(id, newValue).then((res) => {
      if (res.data.success) {
        props.onDataChange();
      }
    });
    setChangeModalOpen(false);
  };

  const handleTotalCollectionChange = (newValue) => {
    const id = [editingRow.current.row.original.id];
    PackageService.updatePackageTotalCollection(id, newValue).then((res) => {
      if (res.data.success) {
        props.onDataChange();
      }
    });
    setChangeModalOpen(false);
  };

  const handleDeliveryDateChange = (newDeliveryDate, isStatusChangeChecked) => {
    const id = [editingRow.current.row.original.id];
    const data = {
      deliveryDate: newDeliveryDate,
    };
    if (isStatusChangeChecked) data.status = "S";

    PackageService.updatePackageDeliveryDate(id, data)
      .then((res) => {
        if (res.data.success) {
          props.onDataChange();
        }
      })
      .catch((error) => {
        console.error("Request failed with error:", error);
      });

    setChangeModalOpen(false);
  };

  const handelLinkToExternalSystem = () => {
    props.onDataChange();
  };

  const table = useMaterialReactTable({
    columns: PackageTableColumn(),
    data,
    enableColumnResizing: true,
    columnRssesizeDirection: "rtl",
    enableRowSelection: true,
    enableRowActions: true,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        // left: ["mrt-row-select", "id"],
        right: ["mrt-row-actions"],
      },
    },
    localization: { ...MRT_Localization_AR },
    enableFullScreenToggle: true,
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    columnFilterDisplayMode: "popover",
    enablePagination: true,
    positionToolbarAlertBanner: "head-overlay",
    enableColumnPinning: true,

    renderRowActionMenuItems: ({ closeMenu, row }) => [
      <MenuItem
        key={0}
        onClick={() => {
          // تعديل قيمة التوصيل logic...
          setChangeModalOpen(true);
          editingRow.current = { row, key: 0 };
          closeMenu();
        }}
        sx={{ m: 0 }}>
        <ListItemIcon>
          <Check />
        </ListItemIcon>
        تعديل قيمة التوصيل
      </MenuItem>,
      <MenuItem
        key={1}
        onClick={() => {
          // تعديل قيمة التحصيل logic...
          setChangeModalOpen(true);
          editingRow.current = { row, key: 1 };
          closeMenu();
        }}
        sx={{ m: 0 }}>
        <ListItemIcon>
          <Check />
        </ListItemIcon>
        تعديل قيمة التحصيل
      </MenuItem>,
      <MenuItem
        key={2}
        onClick={() => {
          // تعين سائق logic...
          closeMenu();
        }}
        sx={{ m: 0 }}>
        <ListItemIcon>
          <Person />
        </ListItemIcon>
        تعين سائق
      </MenuItem>,
      <MenuItem
        key={3}
        onClick={() => {
          // طباعة الشحن logic...
          editingRow.current = { row, key: 3 };
          handlePrintBill();
          closeMenu();
        }}
        sx={{ m: 0 }}>
        <ListItemIcon>
          <Print />
        </ListItemIcon>
        طباعة بوليصة
      </MenuItem>,
      <MenuItem
        key={4}
        onClick={() => {
          // logic تغير تاريخ التسليم
          setChangeModalOpen(true);
          editingRow.current = { row, key: 4 };
          closeMenu();
        }}
        sx={{ m: 0 }}>
        <ListItemIcon>
          <CalendarToday />
        </ListItemIcon>
        تغير تاريخ التوصيل
      </MenuItem>,
      <MenuItem
        key={5}
        onClick={() => {
          // logic ربط مع نظام خارجي
          setChangeModalOpen(true);
          editingRow.current = { row, key: 5 };
          closeMenu();
        }}
        sx={{ m: 0 }}>
        <ListItemIcon>
          <Link />
        </ListItemIcon>
        ربط مع نظام خارجي
      </MenuItem>,
    ],
    onDataChange: props.onDataChange,
    muiTableBodyCellProps: { align: "right" },
    renderTopToolbar: PackageTableTopBar,
  });

  if (!isSetFilter && status) {
    table
      .getAllColumns()
      .find((column) => column.id === "status")
      .setFilterValue([status]);
    setFilter(true);
  }

  return (
    <div style={{ overflowX: "auto" }}>
      <MaterialReactTable table={table} />

      <UpdateDeliveryCost
        isOpen={isChangeModalOpen && editingRow.current.key == 0}
        onClose={() => setChangeModalOpen(false)}
        onChange={handleDeliveryCostChange}
      />
      <UpdateCollectionCost
        isOpen={isChangeModalOpen && editingRow.current.key == 1}
        onClose={() => setChangeModalOpen(false)}
        onChange={handleTotalCollectionChange}
      />
      <ChangeDeliveryDateModel
        isOpen={isChangeModalOpen && editingRow.current.key == 4}
        onClose={() => setChangeModalOpen(false)}
        onChange={handleDeliveryDateChange}
      />
      {editingRow.current && (
        <LinkToExternalSystemModal
          open={isChangeModalOpen && editingRow.current.key == 5}
          onClose={() => setChangeModalOpen(false)}
          packageId={editingRow.current.row.original.id}
          onChanged={handelLinkToExternalSystem}
        />
      )}
    </div>
  );
};

const PackageTable = (props) => {
  const theme = useTheme(); //replace with your theme/createTheme

  // define direction for table to solve pagination Icons is in wrong direction
  const direction = { direction: "rtl" };

  return (
    //Setting Material UI locale as best practice to result in better accessibility
    <ThemeProvider theme={createTheme(theme, arSA, direction)}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Table data={props.data} onDataChange={props.onDataChange} />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default PackageTable;
