import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { Col, FormGroup, Input, Row } from "reactstrap";
import moment from "moment";
import { BANK_LIST } from "variables/constant";
import { useEffect } from "react";

function BankTransfer({ onChange }) {
  const [paymentDate, setPaymentDate] = useState(moment().format("YYYY-MM-DD"));
  const [paymentMethod, setPaymentMethod] = useState();
  const [referenceNumber, setReferenceNumber] = useState();

  const handleDateChange = (dateString) => {
    const selectedMoment = moment(dateString, "YYYY-MM-DD");
    setPaymentDate(selectedMoment.format("YYYY-MM-DD"));
  };

  useEffect(() => {
    onChange({ paymentDate, paymentMethod, referenceNumber });
  }, [paymentDate, paymentMethod, referenceNumber]);

  return (
    <div>
      <Row className="customer-list p-3">
        <Col lg="6">
          <FormGroup>
            <div>
              <label className="form-control-label">التاريخ:</label>
              <DatePicker defaultValue={dayjs()} onChange={handleDateChange} style={{ width: "180px" }} />
            </div>
          </FormGroup>
        </Col>
        <Col lg="6">
          <label className="form-control-label">البنك:</label>
          <select className="form-control form-control-sm" onChange={(e) => setPaymentMethod(e.target.value)}>
            <option>--- يرجى الاختيار ---</option>
            {BANK_LIST.map((bank) => {
              return (
                <option key={bank.key} value={bank.key}>
                  {bank.title}
                </option>
              );
            })}
          </select>
        </Col>
        <Col lg="12">
          <label className="form-control-label"> الرقم المرجعي:</label>
          <Input className="form-control form-control-sm" onChange={(e) => setReferenceNumber(e.target.value)} />
        </Col>
      </Row>
    </div>
  );
}
export default BankTransfer;
