import PaymentDialog from "components/Modals/PaymentModal";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import CollectionService from "service/collection";
import CollectionItems from "./CollectionItems";
import OrganizationInfo from "./OrganizationInfo";
import PaymentInfo from "./PaymentInfo";
import Summary from "./Summary";

const CollectionDetails = (props) => {
  const [collection, setCollection] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    populateCollectionDetails();
  }, [props]);

  const populateCollectionDetails = () => {
    CollectionService.getCollectionDetails(id).then((res) => {
      if (res.data.status === "NEW") {
        setPaymentStatus(true);
      }
      setCollection(res.data);
    });
  };

  const handlePrintCollectionInvoice = (id) => {
    if (id) {
      CollectionService.printCollectionInvoice(id).then((res) => {
        if (res.data.success) {
          const newTab = window.open(process.env.REACT_APP_STATIC_REPORT_URL + res.data.fileName, "_blank");
          if (newTab) {
            newTab.focus();
          }
        }
      });
    }
  };

  const handleReCalculateCollections = (id) => {
    CollectionService.reCalculateCollections(id).then(() => {
      populateCollectionDetails();
    });
  };

  return (
    <>
      <Container className="pt-2 pt-md-8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">تفاصيل كشف التحصيل</h3>
                  </div>
                  <div className="d-flex">
                    {paymentStatus && (
                      <Button
                        color="info"
                        variant="contained"
                        className="mx-2"
                        onClick={() => handleReCalculateCollections(collection.id)}>
                        <i className="fas fa-retweet mx-1" /> إعادة الحساب الإجمالي
                      </Button>
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      className="mx-2"
                      onClick={() => handlePrintCollectionInvoice(collection.id)}>
                      <i className="fas fa-print mx-1" /> طباعة
                    </Button>
                    {paymentStatus && (
                      <Button color="success" variant="contained" className="mx-2" onClick={() => setShowModal(true)}>
                        <i className="fas fa-dollar-sign mx-1" /> دفع
                      </Button>
                    )}
                    {showModal && <PaymentDialog show={showModal} onHide={() => setShowModal(false)} />}
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                {collection && collection.items && (
                  <Row className="m-0">
                    <Col lg="4" className="p-4">
                      <OrganizationInfo organizationId={collection.organizationId} />
                    </Col>
                    <Col lg="4" className="p-4">
                      <Summary
                        totalCollections={collection.totalCollections}
                        totalCost={collection.totalCost}
                        amount={collection.amount}
                      />
                    </Col>
                    <Col lg="4" className="p-4">
                      <PaymentInfo
                        totalCollections={collection.totalCollections}
                        totalCost={collection.totalCost}
                        collection={collection}
                      />
                    </Col>
                    <Col lg="12" className="p-4">
                      <CollectionItems data={collection.items} paymentStatus={paymentStatus} />
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CollectionDetails;
