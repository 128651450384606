import OrdersStatisticWidget from "components/OrdersStatisticWidget";
import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Card, Col, Row } from "reactstrap";
import PackageService from "service/package";
import UtilityService from "service/utility";
import { PACKAGE_STATUS_LIST } from "variables/constant";

// need below line to have react-chartjs-2 work without issue
import "chart.js/auto";

const colors = [
  "#11cdef",
  "#9370DB",
  "#DAA520",
  "#8B008B",
  "#24b47e",
  "#d782d9",
  "#8B0000",
  "#f5365c",
  "#000000",
  "#FF69B4",
  "#5F9EA0",
  "#E9967A",
  "#A9A9A9",
  "#FFA500",
  "#31572C",
  "#c1121f",
  "#0000FF",
  "#11960C",
];

const PackageStatistic = () => {
  const [summaryData, setSummaryData] = useState([]);

  useEffect(() => {
    PackageService.getPackageSummary().then((res) => {
      setSummaryData(res.data);
    });
  }, []);

  const totalPackage = Object.values(summaryData).reduce(
    (accumulator, currentValue) => accumulator + currentValue.count,
    0
  );

  const chartData = {
    labels: PACKAGE_STATUS_LIST.map((status) => UtilityService.renderStatusMessage("PACKAGE", status, false, true)),
    datasets: [
      {
        data: PACKAGE_STATUS_LIST.map((status) => summaryData.find((item) => item.status === status)?.count || 0),
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: `عدد الطرود:  ${totalPackage}`,
        font: {
          size: 25,
        },
        color: "black",
      },
    },

    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 50,
  };

  return (
    <>
      <Row>
        <Col lg="6" xl="8" className="mb-4">
          <Row>
            {PACKAGE_STATUS_LIST.map((status, index) => {
              const obj = summaryData.find((item) => item.status === status);
              return (
                <Col lg="4" xl="3" className="mb-4" key={index}>
                  <OrdersStatisticWidget
                    totalPackage={totalPackage}
                    title={UtilityService.renderStatusMessage("PACKAGE", status, false, true)}
                    value={obj?.count || 0}
                    color={colors[index]}
                    status={status}
                  />
                </Col>
              );
            })}
          </Row>
        </Col>
        <Col lg="6" xl="4" className="mb-4">
          <Card>
            <div className="chart mb-4">
              <Doughnut data={chartData} options={options} />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PackageStatistic;
