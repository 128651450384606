import { useState } from "react";
import { Button, FormGroup, Input, Modal } from "reactstrap";
import PackageService from "service/package";

const DEFAULT_VALUES = {
  errorMessage: "",
  externalSystemName: "",
  externalSystemRefrences: "",
  externalSystemURL: "",
};

const LinkToExternalSystemModal = ({ packageId, onClose, onChanged, open }) => {
  const [data, setData] = useState(DEFAULT_VALUES);

  const closeModal = () => {
    setData(DEFAULT_VALUES);
    onClose();
  };

  // Function to update a single key in the state
  const updateData = (name, value) => {
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    updateData("errorMessage", "");

    if (data.externalSystemName && data.externalSystemRefrences) {
      PackageService.linkPackageToExternalSystem(packageId, {
        externalSystem: data.externalSystemName,
        externalNumber: data.externalSystemRefrences,
        externalURL: data.externalSystemURL,
      })
        .then((res) => {
          if (onChanged) {
            onChanged();
          }
          closeModal();
        })
        .catch(() => {
          updateData("errorMessage", "ناسف, فشل في حفظ البيانات. الرجاء المحاولة مرة اخرى لاحقا.");
        });
    } else {
      updateData("errorMessage", "الرجاء ملى الحقول المطلوبة");
    }
  };

  const { externalSystemName, errorMessage, externalSystemRefrences, externalSystemURL } = data;

  return (
    <>
      <Modal className="modal-dialog-centered" isOpen={open} toggle={closeModal}>
        <div className="modal-body pb-0">
          <div justify-content="center">
            <div>
              <div>
                <FormGroup className="mb-2">
                  <label className="form-control-label">النظام الخارجي:</label>
                  <select
                    value={externalSystemName}
                    className="form-control"
                    onChange={(e) => updateData("externalSystemName", e.target.value)}>
                    <option value=""></option>
                    <option value="KANGAROO">Kangaroo</option>
                    <option value="PHOENIX">PHOENIX</option>
                    <option value="SEKKA">Sekka</option>
                    <option value="ع . م للتوصيل / ע . מ משלוחים">ع . م للتوصيل / ע . מ משלוחים</option>
                  </select>
                </FormGroup>
              </div>
              <div>
                <FormGroup className="mb-2">
                  <label className="form-control-label">الرمز المرجعي:</label>
                  <Input
                    className="form-control"
                    type="text"
                    value={externalSystemRefrences}
                    onChange={(e) => updateData("externalSystemRefrences", e.target.value)}
                  />
                </FormGroup>
              </div>
              <div>
                <FormGroup className="mb-2">
                  <label className="form-control-label">الرابط:</label>
                  <Input
                    className="form-control"
                    type="text"
                    value={externalSystemURL}
                    onChange={(e) => updateData("externalSystemURL", e.target.value)}
                  />
                </FormGroup>
              </div>
              <p className="text-danger mb-0 mt-2">&nbsp;{errorMessage}</p>
            </div>
          </div>
        </div>

        <div className="modal-footer pt-0">
          <Button color="primary" type="button" className="w-100" onClick={handleSubmit}>
            حفظ
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default LinkToExternalSystemModal;
