import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Container } from "reactstrap";
import PackageService from "service/package";
import PackageTable from "./PackageTable";

const ActivePackages = (props) => {
  const [packageList, setPackageList] = useState([]);

  useEffect(() => {
    populatePackgeList();
  }, [props]);

  const populatePackgeList = () => {
    trackPromise(
      PackageService.getActivePackagesList().then((res) => {
        setPackageList(res.data);
      })
    );
  };

  return (
    <Container className="pt-2 pt-md-8 mb-4" fluid>
      {packageList && <PackageTable data={packageList} onDataChange={populatePackgeList} />}
    </Container>
  );
};

export default ActivePackages;
