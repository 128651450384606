import { DatePicker } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, FormGroup, Row } from "reactstrap";

function Cash({ onChange }) {
  const [paymentDate, setPaymentDate] = useState(moment().format("YYYY-MM-DD"));

  const handleDateChange = (dateString) => {
    const selectedMoment = moment(dateString, "YYYY-MM-DD");
    setPaymentDate(selectedMoment.format("YYYY-MM-DD"));
  };

  useEffect(() => {
    onChange({ paymentDate });
  }, [paymentDate]);

  return (
    <Row>
      <Col lg="6">
        <FormGroup>
          <div className="pl-lg-4">
            <label className="form-control-label">التاريخ:</label>
          </div>
          <DatePicker
            defaultValue={dayjs()}
            onChange={handleDateChange}
            style={{ width: "205px", marginRight: "10px" }}
          />
        </FormGroup>
      </Col>
    </Row>
  );
}
export default Cash;
